import { teamsService } from '@services';

const useTeamsCRUD = () => {
    const create = async (data) => {
        try {
            const { error, response } = await teamsService.createTeam(data);

            if (error != null) {
                if(error === 'team_name_already_exists'){
                    throw new Error('El nombre del equipo ya existe');
                }
                if(error === 'team_logo_required'){
                    throw new Error('El logo del equipo es requerido');
                }
                else{
                    throw new Error(error);
                }
            }

            return {
                error: null,
                response: response,
            };
        } catch (error) {
            return {
                error: error.message,
                response: null,
            };
        }
    };

    const update = async ({data}) => {
        try {
            const {error, response} = await teamsService.logout();

            if (error) {
                throw new Error('No se pudo actualizar el equipo.');
            }

            return {
                error: null,
                response: response,
            };
        } catch (error) {
            return {
                error: error.message,
                response: null,
            };
        }
    };

    const remove = async () => {
        try {
            const {error, response} = await teamsService.logout();

            if (error) {
                throw new Error('No se pudo eliminar el equipo');
            }

            return {
                error: null,
                response: response,
            };
        } catch (error) {
            return {
                error: error.message,
                response: null,
            };
        }
    };

    return { create, update, remove };
};

export default useTeamsCRUD;

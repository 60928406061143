import axios from 'axios';
import { toast } from 'react-toastify';

const fetchWithToken = async (url, options) => {
  try {
    const token = localStorage.getItem('token');
    if (!token) {
      throw new Error('Token not found in localStorage');
    }

    const response = await axios(url, {
      ...options,
      headers: {
        ...options.headers,
        Authorization: `Bearer ${token}`
      }
    });

    const { headers } = response;

    if (headers['x-token'] && headers['x-sessiontoken']) {
        // Obtenesmos los token y sessionToken anteriores
        const token = localStorage.getItem('token');
        const sessionToken = localStorage.getItem('sessionToken');

        // Validamos si son diferentes
        if(token !== headers['x-token']){
            localStorage.setItem('token', headers['x-token']);
            console.log("Token actualizado");
        }
        
        if(sessionToken !== headers['x-sessionToken']){
            localStorage.setItem('sessionToken', headers['x-sessiontoken']);
            console.log("SessionToken actualizado");
        }
    }

    return response.data;

  } catch (error) {
    return {
      error: error.response.data.error || error.message,
      response: null
    };
  }
};

export default fetchWithToken;

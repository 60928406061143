import { useState, useEffect } from 'react';
import { eventsService } from '@services';

function useEventTeam(eventId, teamId) {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchEvents = async () => {
      setLoading(true); // Inicia la carga
      setError(null); // Resetea cualquier error previo

      try {
        const teamData = await eventsService.team(eventId, teamId); // Llama al servicio para obtener los datos del equipo

        // Verificación de la estructura de la respuesta para evitar errores
        if (teamData?.response) {
          const { team } = teamData.response;
          setData(team); // Marca la operación como exitosa
        } else {
          throw new Error("Datos incompletos o no válidos en la respuesta.");
        }
      } catch (error) {
        setError(error.message || "Error al obtener los datos del equipo.");
      } finally {
        setLoading(false); // Finaliza la carga
      }
    };

    fetchEvents();
  }, [eventId, teamId]);

  return {
    data,
    loading,
    error,
  };
};

export default useEventTeam;

import fetchWithToken from './fetchWithToken';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const teamsService = {
  // Obtener todos los equipos
  getAllTeams: async () => {
    const url = `${API_BASE_URL}teams`;
    const options = {
      method: 'GET'
    };

    return fetchWithToken(url, options);
  },

  // Crear un nuevo equipo
  createTeam: async ({name, logo}) => {

    console.log(logo[0]);

    let payload = new FormData();

    if(name) payload.append('name', name);
    if(logo[0]) payload.append('logotype', logo[0]);

    const url = `${API_BASE_URL}teams`;
    const options = {
      method: 'POST',
      headers: {'Content-Type': 'multipart/form-data'},
      data: payload // Use body instead of data
    };

    return fetchWithToken(url, options);
  },

  // Obtener detalles de un equipo específico
  getTeamDetails: async (teamId) => {
    const url = `${API_BASE_URL}teams/${teamId}`;
    const options = {
      method: 'GET'
    };

    return fetchWithToken(url, options);
  },

  // Actualizar detalles de un equipo específico
  updateTeam: async (teamId, teamData) => {
    const url = `${API_BASE_URL}teams/${teamId}`;
    const options = {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify(teamData)
    };

    return fetchWithToken(url, options);
  },

  // Eliminar un equipo específico
  deleteTeam: async (teamId) => {
    const url = `${API_BASE_URL}teams/${teamId}`;
    const options = {
      method: 'DELETE'
    };

    return fetchWithToken(url, options);
  },

  // Obtener miembros de un equipo específico
  getTeamMembers: async (teamId) => {
    const url = `${API_BASE_URL}teams/${teamId}/members`;
    const options = {
      method: 'GET'
    };

    return fetchWithToken(url, options);
  },

  // Obtener miembros pendientes de un equipo específico
  getPendingMembers: async (teamId) => {
    const url = `${API_BASE_URL}teams/${teamId}/members/pending`;
    const options = {
      method: 'GET'
    };

    return fetchWithToken(url, options);
  },

  // Aceptar la solicitud de un miembro en un equipo específico
  acceptMemberRequest: async (teamId, userId) => {
    const url = `${API_BASE_URL}teams/${teamId}/members/${userId}/accept`;
    const options = {
      method: 'GET'
    };

    return fetchWithToken(url, options);
  },

  // Rechazar la solicitud de un miembro en un equipo específico
  rejectMemberRequest: async (teamId, userId) => {
    const url = `${API_BASE_URL}teams/${teamId}/members/${userId}/reject`;
    const options = {
      method: 'GET'
    };

    return fetchWithToken(url, options);
  },

  // Añadir un miembro a un equipo específico
  addTeamMember: async (teamId, memberData) => {
    const url = `${API_BASE_URL}teams/${teamId}/members`;
    const options = {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify(memberData)
    };

    return fetchWithToken(url, options);
  },

  // Eliminar un miembro de un equipo específico
  removeTeamMember: async (teamId, memberId) => {
    const url = `${API_BASE_URL}teams/${teamId}/members/${memberId}`;
    const options = {
      method: 'DELETE'
    };

    return fetchWithToken(url, options);
  },

  // Transferir propiedad de un equipo específico
  transferTeamOwnership: async (teamId, transferData) => {
    const url = `${API_BASE_URL}teams/${teamId}/members/transfer`;
    const options = {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify(transferData)
    };

    return fetchWithToken(url, options);
  },
};

export default teamsService;

import { useState, createContext, useContext, useEffect } from "react";
import { authService } from "@services";

const UserContext = createContext(undefined);

export const UserProvider = ({ children }) => {
  const [dataUser, setDataUser] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);

  // Verificar la autenticación al cargar la aplicación
  useEffect(() => {
    const checkAuthentication = async () => {
      setLoading(true); // Asegúrate de que el estado de carga esté activado al comenzar

      try {
        // Validamos token si este existe
        const token = localStorage.getItem("token");

        if (!token) {
          throw new Error("No hay token");
        }

        const userData = await authService.verifyToken();

        // Verifica si la respuesta contiene un error
        if (userData.error) {
          throw new Error(userData.error);
        }

        setDataUser(userData.response); // Actualiza la información del usuario desde el backend
        setIsAuthenticated(true);
      } catch (error) {
        setIsAuthenticated(false);
        setDataUser(null);
      } finally {
        setLoading(false);
      }
    };

    checkAuthentication();
  }, []);

  // Método para iniciar sesión
  const handleLogin = async ({ username, password }) => {
    setIsAuthenticated(false); // Establecemos el estado como no autenticado al iniciar el proceso

    try {
      // Realizamos la solicitud de inicio de sesión
      const auth = await authService.login({
        email: username,
        password: password,
      });

      // Verificamos si hubo un error en la respuesta
      if (auth.code === "ERROR") {
        throw new Error(auth.error);
      }

      // Guardamos los tokens en localStorage
      localStorage.setItem("token", auth.response.token);
      localStorage.setItem("sessionToken", auth.response.session_token);

      // Validamos token si este existe
      const token = localStorage.getItem("token");

      if (!token) {
        throw new Error("No hay token");
      }

      // Verificamos el token y actualizamos la información del usuario
      const userData = await authService.verifyToken();

      // Verificamos si la respuesta contiene un error
      if (userData.error) {
        throw new Error(userData.error);
      }

      setDataUser(userData.response); // Actualizamos la información del usuario
      setIsAuthenticated(true); // Establecemos el estado como autenticado
    } catch (error) {
      setIsAuthenticated(false); // Asegúrate de que el estado de autenticación esté desactivado si hay un error
      throw new Error(error.message);
    } finally {
      setLoading(false); // Asegúrate de que el estado de carga esté desactivado al finalizar
    }
  };

  // Método para cerrar sesión
  const handleLogout = async () => {
    await authService.logout();
    localStorage.removeItem("sessionToken");
    localStorage.removeItem("token");
    setDataUser(null);
    setIsAuthenticated(false);
  };

  return (
    <UserContext.Provider
      value={{
        dataUser,
        isAuthenticated,
        loading,
        handleLogin,
        handleLogout,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUserProvider = () => useContext(UserContext);

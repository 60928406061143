import axios from "axios";

class httpService {
  constructor({ baseUrl, useToken = false, defaultHeaders = {} }) {
    this.client = axios.create({
      baseURL: baseUrl,
      headers: defaultHeaders,
    });

    // Interceptor para añadir token si es necesario
    if (useToken) {
      this.client.interceptors.request.use(
        (config) => {
          const token = localStorage.getItem("token"); // Ajustar la obtención del token según tu contexto
          if (token) {
            config.headers.Authorization = `Bearer ${token}`;
          }
          return config;
        },
        (error) => Promise.reject(error)
      );
    }
  }

  async request(method, url, data = null) {
    try {
      const response = await this.client.request({ method, url, data });
      return response.data;
    } catch (error) {
      // Manejo de errores centralizado
      const errorMessage = this.handleError(error);
      throw new Error(errorMessage);
    }
  }

  get(url, params) {
    return this.request("get", url, { params });
  }

  post(url, data) {
    return this.request("post", url, data);
  }

  put(url, data) {
    return this.request("put", url, data);
  }

  delete(url, data) {
    return this.request("delete", url, data);
  }

  handleError(error) {
    if (error.message === "Network Error") {
      return "Error de conexión a internet, por favor intente más tarde.";
    }
    if (error.response?.status === 401) {
      return "No autorizado, por favor inicie sesión nuevamente.";
    }
    // Otros errores que quieras manejar específicamente
    return (
      error.response?.data?.message ||
      "Error en el servidor, intente más tarde."
    );
  }
}

export default httpService;

// authService.js
import { toast } from "react-toastify";
import { apiPrivate, apiPublic } from "./apiClient"; // Importa las instancias compartidas

const authService = {
  verifyToken: async () => {
    try {
      return await apiPrivate.get("me");
    } catch (error) {
      return {
        error: error.message || "Error verificando el token.",
        response: null,
      };
    }
  },

  login: async (params) => {
    try {
      const data = {
        email: params.email,
        password: params.password,
      };

      const response = await apiPublic.post("login", data);
      return response;
    } catch (error) {
      if (error.message === "Network Error") {
        return {
          code: "ERROR",
          error:
            "Error en su conexión a internet, por favor intente más tarde.",
          response: null,
        };
      }

      if (error.response?.status === 401) {
        return {
          code: "ERROR",
          error: "Correo o contraseña incorrectos.",
          response: null,
        };
      }

      return {
        code: "ERROR",
        error: "Error en el servidor, por favor intente más tarde.",
        response: null,
      };
    }
  },

  logout: async () => {
    try {
      const response = await apiPrivate.get("close");
      if (response.status === 200) {
        return { error: null };
      }
    } catch (error) {
      return { error: "No se pudo cerrar la sesión" };
    }
  },

  register: async (params) => {
    try {
      // Formateamos la fecha a yyyy-mm-dd
      const birth = params.birth.split("/");
      params.birth = `${birth[2]}-${birth[1]}-${birth[0]}`;

      const data = {
        name: params.fullName,
        email: params.email,
        password: params.password,
        password_confirmation: params.passwordConfirm,
        user_name: params.userName,
        birthdate: params.birth,
        type_account: params.typeAccount.value,
        country: params.country.value,
        city: params.city.value,
      };

      const response = await apiPublic.post("register", data);
      return response;
    } catch (error) {
      const responseError = error.response?.data?.error;

      if (responseError === "The given data was invalid.") {
        const errorResponse = error.response?.data?.response || {};

        if (errorResponse.user_name) {
          toast.error("El nombre de usuario ya está en uso");
        }

        if (errorResponse.email) {
          toast.error("El correo electrónico ya está en uso");
        }

        if (errorResponse.birthdate) {
          handleBirthdateErrors(errorResponse.birthdate);
        }
      }

      return {
        code: "ERROR",
        error: error.message || "Error al registrarse.",
        response: null,
      };
    }
  },
};

// Función para manejar los errores de fecha de nacimiento
function handleBirthdateErrors(birthdateErrors) {
  const date = new Date();
  const year = date.getFullYear() - 17;
  const year2 = date.getFullYear() - 11;

  birthdateErrors.forEach((error) => {
    if (
      error ===
      `The birthdate field must be a date after or equal to ${year}-01-01.`
    ) {
      toast.error("Debes tener al menos 18 años para registrarte como tutor");
    } else if (
      error ===
      `The birthdate field must be a date before or equal to ${year2}-12-31.`
    ) {
      toast.error(
        "Debes tener entre 11 y 17 años para registrarte como estudiante de secundaria"
      );
    } else if (
      error ===
      `The birthdate field must be a date before or equal to ${
        year2 - 5
      }-12-31.`
    ) {
      toast.error(
        "Debes tener más de 16 años para registrarte como estudiante universitario"
      );
    } else {
      toast.error(
        "Vaya, parece que eres muy joven para registrarte como estudiante universitario o tutor"
      );
    }
  });
}

export default authService;

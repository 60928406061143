import {createContext, useContext, useEffect, useState} from 'react';
import {isRtlLang} from 'rtl-detect';

const ThemeContext = createContext(undefined);

export const ThemeProvider = ({children}) => {
    const page = document.documentElement;
    const isRtl = isRtlLang(navigator.language);
    const persisted = JSON.parse(localStorage.getItem('preferences') || '{}');

    const persistedToken = localStorage.getItem('token') || null;
    const persistedUser = JSON.parse(localStorage.getItem('user') || null);

    const [theme, setTheme] = useState('light');
    const [fontScale, setFontScale] = useState(persisted.fontScale || 1);
    const [direction, setDirection] = useState(persisted.direction || (isRtl ? 'rtl' : 'ltr'));
    const [dataUser, setDataUser] = useState(persistedUser);
    const [dataToken, setDataToken] = useState(persistedToken);

    const stopTransition = () => {
        page.classList.add('no-transition');
        setTimeout(() => page.classList.remove('no-transition'), 100);
    }

    const savePreferences = () => {
        localStorage.setItem('preferences', JSON.stringify({
            theme,
            fontScale,
            direction
        }));
    }

    const toggleTheme = () => {
        setTheme(theme === 'light' ? 'dark' : 'light');
        stopTransition();
    }

    const changeFontScale = (scale) => {
        setFontScale(scale);
        stopTransition();
    }

    const toggleDirection = () => {
        setDirection(direction === 'ltr' ? 'rtl' : 'ltr');
        page.setAttribute('dir', direction);
    }

    const setToken = (data) => {
        setDataToken(data);
        localStorage.setItem('token', data);
    }

    const setUser = (data) => {
        setDataUser(data);
        localStorage.setItem('user', JSON.stringify(data));
    }

    useEffect(() => {
        page.style.setProperty('--font-scale', fontScale);
        page.style.setProperty('--widget-scale', fontScale === 1 ? '0px' : `${fontScale * 3}px`);
        page.setAttribute('dir', direction);
        savePreferences();

        window
            .matchMedia('(prefers-color-scheme: light)')
            .addEventListener('change', event => {
                event.matches ? setTheme('light') : setTheme('dark');
                stopTransition();
                savePreferences();
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [theme, fontScale, direction]);

    return (
        <ThemeContext.Provider
            value={{
                theme,
                fontScale,
                direction,
                dataUser,
                dataToken,
                toggleTheme,
                changeFontScale,
                toggleDirection,
                setUser,
                setToken
            }}>
            {children}
        </ThemeContext.Provider>
    );
}

export const useThemeProvider = () => useContext(ThemeContext);
import { useState, useEffect } from 'react';
import { teamsService } from '@services';

function useTeamsDetailService(teamId) {
  const [team, setTeam] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [authorization, setAuthorization] = useState(null); // Estado para la autorización

  const fetchTeamDetails = async () => {
    try {
      setLoading(true);
      const response = await teamsService.getTeamDetails(teamId);

      console.log(response);

      // Manejo de errores HTTP
      // if (!response.ok) {
      //   if (response.status === 403) {
      //     // Acceso prohibido
      //     setAuthorization({ authorized: false, reason: 'Acceso prohibido' });
      //   }
      //   return;
      // }

      setTeam(response.response.team);
      setLoading(false);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTeamDetails();
  }, [teamId]); // Se ejecuta cuando cambia teamId

  // Función para recargar los detalles del equipo
  const refreshTeam = () => {
    fetchTeamDetails();
  };

  return { team, loading, error, refreshTeam, authorization };
}

export default useTeamsDetailService;

// eventsService.js
import { apiPrivate } from "./apiClient"; // Importa la instancia compartida de apiPrivate

const eventsService = {
  get: async () => {
    try {
      const response = await apiPrivate.get("events");
      return response;
    } catch (error) {
      return {
        code: "ERROR",
        error: error.message || "Error al obtener los eventos.",
        response: null,
      };
    }
  },

  detail: async (eventId) => {
    try {
      const response = await apiPrivate.get(`events/${eventId}`);
      return response;
    } catch (error) {
      return {
        code: "ERROR",
        error:
          error.message || `Error al obtener el detalle del evento ${eventId}.`,
        response: null,
      };
    }
  },

  calendar: async (eventId) => {
    try {
      const response = await apiPrivate.get(`events/${eventId}/calendar`);
      return response;
    } catch (error) {
      return {
        code: "ERROR",
        error:
          error.message ||
          `Error al obtener el calendario del evento ${eventId}.`,
        response: null,
      };
    }
  },

  teams: async (eventId) => {
    try {
      const response = await apiPrivate.get(`events/${eventId}/teams`);
      return response;
    } catch (error) {
      return {
        code: "ERROR",
        error:
          error.message ||
          `Error al obtener los equipos del evento ${eventId}.`,
        response: null,
      };
    }
  },

  team: async (eventId, teamId) => {
    try {
      const response = await apiPrivate.get(
        `events/${eventId}/teams/${teamId}`
      );
      return response;
    } catch (error) {
      return {
        code: "ERROR",
        error:
          error.message ||
          `Error al obtener el equipo ${teamId} del evento ${eventId}.`,
        response: null,
      };
    }
  },
};

export default eventsService;

import { useState, useEffect } from 'react';
import { teamsService } from '@services';

function useTeamsService() {
  const [teams, setTeams] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [reload, setReload] = useState(false);

  const fetchEvents = async () => {
      try {
        const eventsData = await teamsService.getAllTeams(); 
        console.log(eventsData);// Suponiendo que tienes una función get() en tu servicio
        setTeams(eventsData.response.teams);
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
  };

  useEffect(() => {
    fetchEvents();
  }, [reload]);

  const refresh = () => {
    setLoading(true);
    setReload(!reload);
  }

  return { teams, loading, error, refresh };
}

export default useTeamsService;
